import React, { useRef, useState } from "react";
import axios from "axios";
import "./style.scss";

import { ReactComponent as Globe } from "../../assets/globe.svg";
import { ReactComponent as Call } from "../../assets/call.svg";
import { ReactComponent as Plus } from "../../assets/plus.svg";
import { ReactComponent as Send } from "../../assets/send.svg";

import { PopupButton } from "react-calendly";

import calendly from "../../assets/calendly.svg";

import ScrollDisabler from "../../ScrollDisabler";

const Contact = ({ setWaiting }) => {
  const [contact, setContact] = useState("");
  const [errContact, setErrContact] = useState(false);
  const [name, setName] = useState("");
  const [errName, setErrName] = useState(false);

  const calendlyRef = useRef();

  const validationEmail = (contact) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(contact).toLowerCase());
  };

  const validationNumber = (contact) => {
    const re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/;
    return re.test(contact);
  };

  const submitMail = async (e) => {
    if (name.trim() === "" && contact.trim() === "") {
      setErrName(true);
      setErrContact(true);
      return;
    }
    if (name.trim() === "") {
      setErrName(true);
      return;
    }
    if (contact.trim() === "") {
      setErrContact(true);
      return;
    }
    e.preventDefault();
    if (
      !validationEmail(contact) &&
      (!validationNumber(contact) || contact.length < 6)
    ) {
      setErrContact(true);
      return;
    }
    ScrollDisabler.disable();
    setWaiting(true);
    try {
      await axios.post(`https://api.todor3d.com/api/contact`, {
        contact,
        name,
      });
      //   await axios.post(`http://localhost:3001/api/contact`, {contact, name});
      setContact("");
      setName("");
      setWaiting(false);
      ScrollDisabler.enable();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="wrapper-contact-t3d" id="contact">
      <div className="light-cube"></div>
      <div className="light-circle"></div>
      <div className="container-content-contact">
        <div className="block-contact-center">
          <div className="header-contact">
            <div className="containter-left-block-contant">
              <div className="title-left-block-contant">
                Have an amazing project to <span>work on</span>?
              </div>
            </div>
            <div className="header-location-contact max-size">
              <Globe />
              <div className="item-footer">
                • Miami, <span>USA</span>
              </div>
              <div className="item-footer">
                • Dubai, <span>UAE</span>
              </div>
              <div className="item-footer">
                • Tallin, <span>EST</span>
              </div>
              <div className="item-footer">
                • Odesa, <span>Ukraine</span>
              </div>
            </div>
          </div>
          <div className="body-contact">
            <div className="left-block-contant">
              <div className="left-block-content-contant">
                <div className="request-a-call-contact">
                  <Call className="request-a-call-contact-call" />
                  <div className="block-text-request-a-call-contact">
                    <div className="line-request-a-call-contact"></div>
                    <div className="title-request-a-call-contact">
                      Need a call?
                    </div>
                    <div className="discription-request-a-call-contact">
                      Book a call with our Sales Team.
                    </div>
                  </div>
                  <span style={{ display: "none" }}>
                    <PopupButton
                      ref={calendlyRef}
                      url="https://calendly.com/todor3d/lets-discuss-your-project"
                      rootElement={document.getElementById("root")}
                      text="Book"
                    ></PopupButton>
                  </span>
                  <div
                    className="btn-request-a-call-contact"
                    onClick={() => {
                      calendlyRef.current.onClick(new Event("click"));
                    }}
                  >
                    <span>Book</span> <Plus />
                  </div>
                  <div className="title-using-services">
                    *powered by
                    <img src={calendly} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="container-right-block-contant">
              <div className="right-block-contant">
                <div className="title-content-contact">
                  Get <span>in touch</span>
                </div>
                <form className="form-contact" onSubmit={submitMail}>
                  <div className="wrapper-input">
                    <input
                      type="text"
                      className={
                        errName ? "item-form-input err" : "item-form-input"
                      }
                      value={name}
                      onChange={(e) => {
                        setErrName(false);
                        setName(e.target.value);
                      }}
                      placeholder="Name"
                    />
                    <input
                      type="text"
                      className={
                        errContact ? "item-form-input err" : "item-form-input"
                      }
                      value={contact}
                      onChange={(e) => {
                        setErrContact(false);
                        setContact(e.target.value);
                      }}
                      placeholder="Your E-mail or WhatsApp"
                    />
                  </div>
                  <div className="wrapper-btn-send-form-contact">
                    <div className="btn-send-form-contact" onClick={submitMail}>
                      <span>Submit</span> <Send />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
